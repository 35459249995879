import router from "../router";
import Toasted from 'vue-toasted';
import Vue from "vue"
import i18n from "@/plugins/vue-i18n";

Vue.use(Toasted);

export const checkErrors = function(error) {
    if(error.response.status === 401){
        router.push({ name: 'login' })
        Vue.toasted.error(i18n.t('message.' + error.response.data.message));
    }
    if(error.response.status === 403){
        router.push({ name: 'login' })
        Vue.toasted.error(i18n.t('message.' + error.response.data.message));
    }
    if(error.response.status === 404){
        Vue.toasted.error(i18n.t('message.' + error.response.data.message));
    }
    if(error.response.status === 422){
        Vue.toasted.error("Data nejsou validní.");
        return error.response.data.errors;
    }
    if(error.response.status === 500){
        Vue.toasted.error("Nastala interní chyba na backendu.");
        return error.response.data.errors;
    }
}

export const errorFor = function(field){
    return null != this.errors ? this.errors[field] : null;
}

