<template>
    <div class="orders-bg">
      <div class="orders-container container">
        <div class="orders-top-content">
          <Menu></Menu>
        </div>
        <div class="orders-content orders-month-content px-5 py-4" style="position: relative">

          <router-link :to="{ name: 'orders_new' }" style="background-color: #f3f3f3 !important; right: 320px; @media screen and (min-width: 992px) {position: absolute}" class="d-inline-block px-3 py-2 orders-new-month-calendar-button">
            <img
              src="../../../assets/img/calendar.png"
              alt="/"
              style="width: 30px;"
              class="d-inline-block py-1"
            /><p class="d-inline-block mb-0 ps-3 text-body">{{ $t('orders.title.week_order')}}</p>
          </router-link>  

          <FullCalendar :options="calendarOptions"  />

          <center class="mt-4">
            <b-button
                variant="success"
                size="lg"
                v-b-modal.modal-confirm-order-month
              >
                {{ $t("orders.button.order_month") }}
            </b-button>
          </center>

            <div class="orders-new-month-footer mt-4 d-sm-flex justify-content-between">
              <div class="d-flex align-items-center gap-3"><div class="meal-checkbox"></div><p class="mb-0">Objednaná varianta stravy</p></div>
              <div class="pt-4 pt-sm-0"><p class="mb-0 d-inline">Pro <span class="fw-bold" style="color: #800e0e">zrušení</span> objednávky stačí znovu kliknout na</p> <div style="transform: translateY(10px)" class="meal-checkbox d-inline-block ms-2"></div></div>
            </div>
        </div>

      </div>

    <b-modal
        id="modal-confirm-order-month"
        :title="$t('orders.title.confirm_month_order_title')"
      >
        <span
          class="my-4 p-10 px-5 d-block"
          v-html="$t('orders.title.confirm_month_order_text')"
        ></span>
        <template #modal-footer="{ cancel }">
          <b-button variant="secondary"  @click="cancel()" class="mr-5 ">
            {{ $t("button.cancel") }}
          </b-button>
          <b-button variant="success" @click="monthOrder()">
            {{ $t("orders.button.order_month") }}
          </b-button>
        </template>
      </b-modal>

    </div>
</template>

<script>
import i18n from "@/plugins/vue-i18n";
import Menu from "@/views/core/Menu"
import ApiService from "@/services/api.service";
import { checkErrors } from "@/utils/api-errors";
import moment from "@/plugins/moment";

import '@fullcalendar/core/vdom' 
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all';

export default {
  metaInfo () { 
      return { title: i18n.t("meta.orders_new")} 
  },
  data() {
    return {
      firstDay: null,
      startRange: null,
      endRange: null,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin
        ],
        events: null,
        initialView: 'dayGridMonth',
        showNonCurrentDates: false,
        headerToolbar: {
          left: 'title',
          right: 'prev,today,next'
        },
        timeZone: 'Europe/Prague',
        editable: false,
        selectable: true,
        height: 'auto',
        locales: allLocales,
        locale: 'cs',
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        datesSet: this.handleMonthChange,
        nowIndicator: false,
        dayMaxEventRows: 1,
      },
    }
  },
  components: {
    Menu, FullCalendar
  },
  mounted() {
    this.getLastSearch();
    this.getPreOrders();
  },
  watch: {
    firstDay: function()
    {
      this.getPreOrders();
    }
  },
  computed: {
    orderMonthSearch: {
      get () {
        return this.$store.state.orders.orderMonthSearch;
      },
      set (value) {
        this.$store.commit('setOrderMonthSearch', value);
      }
    }
  },
  methods: {
    handleDateSelect(event){
       this.changeDayPreorder(event.startStr);
    },
    handleEventClick(event)
    {
      ApiService.setHeader();      
      ApiService.apiPost("/diner/orders/" + event.event.id + "/cancel")
        .then(() => {
            this.loading = false;
            this.getPreOrders();
        }).catch(error => {
            checkErrors(error);
      });
    },
    getPreOrders() {  
        this.loading = true;
        ApiService.setHeader();
        ApiService.apiGet(
        "diner/orders?from=" + moment(this.startRange).format("YYYY-MM-DD") + "&to=" + moment(this.endRange).format("YYYY-MM-DD") + "&page=1&perpage=1000")
        .then((response) => {
          this.parseResponse(response.data.data);
          this.loading = false;
        });
    },
    parseResponse(data)
    {
      const array = [];
      Object.entries(data).forEach(([key, order]) => {
        if(order.state == 'PREORDER')
        {
            var tempArray = {
            date: order.meal_date,
            id: order.order_id,
            key: key
          }
          array.push(tempArray);
          tempArray = null;
        }
      });

      this.calendarOptions.events = array;
    },
    changeDayPreorder(date)
    {
        ApiService.setHeader();      
        ApiService.apiPost("/diner/preorder", {
          date: date
        })
          .then(() => {
              this.getPreOrders();
          }).catch(error => {
              checkErrors(error);
        });
    },
    handleMonthChange(state) {
      this.firstDay = state.startStr;
      this.startRange = state.startStr;
      this.endRange = state.endStr;

      this.orderMonthSearch = {
        firstDay: this.firstDay
      };
    },
    monthOrder(){
      ApiService.setHeader();      
        ApiService.apiPost("/diner/preorders", {
          month: moment(this.firstDay).format("MM"),
          year: moment(this.firstDay).format("YYYY"),
        })
          .then(() => {
              this.loading = false;
              this.getPreOrders();
              this.$bvModal.hide('modal-confirm-order-month');
          }).catch(error => {
              checkErrors(error);
        });
    },
    getLastSearch()
    {
      let lastSearch = this.$store.state.orders.orderMonthSearch;
      if(lastSearch.firstDay != null)
      {
        this.firstDay = lastSearch.firstDay;
      }
    }
  }
};
</script>